import { useRecoilState } from "recoil";
import { isVFUNListState } from "../recoil/recoil";
import "./MenuToggle.scss";
import { VFUN_IMAGE_DNS } from "utils/config/Config";

function MenuToggle() {
  const [isVFUNList, setIsVFUNList] = useRecoilState(isVFUNListState);

  return (
    <div id="vfun-list-toggle" style={{ color: "#fff" }}>
      <button onClick={() => setIsVFUNList((prev) => !prev)}>
        <img
          src={`${VFUN_IMAGE_DNS}/web/vfun/images/logo/vfun-list-${
            isVFUNList ? "on" : "off"
          }.png`}
        />
      </button>
    </div>
  );
}

export default MenuToggle;
