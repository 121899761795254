//// 페이지 이동 함수
const data = [
  "vfun",
  "vfun-lounge",
  // "billing",
  "vfun-stage",
  "vfun-lounge-stage"
  // "billing-qa"
];

export function redirectURL(url: string, target: string, isLauncher: boolean) {
  // vfun, vfun-lounge, vfun-ground, billing,
  // vfun-stage, vfun-lounge.stage, vfun-ground-stage, billing-qa
  // 포함하면 self, 아님 blank

  const contains = data.some((domain) => url.split(/[\./]/).includes(domain));

  if (isLauncher && !contains) {
    window.app.vfunAuthWebExecute(url);
    return;
  } else {
    window.open(url, target);
  }
}

export const dateFormType1 = (date: string): string => {
  const creat = new Date(date);
  let month = String(creat.getMonth() + 1).padStart(2, "0");
  let day = String(creat.getDate()).padStart(2, "0");

  return `${creat.getFullYear()}-${month}-${day}`;
};

export const dateFormType2 = (date: string): string => {
  const creat = new Date(date);
  let month = String(creat.getMonth() + 1).padStart(2, "0");
  let day = String(creat.getDate()).padStart(2, "0");

  return `${creat.getFullYear()}.${month}.${day}`;
};

export const dateFormType3 = (date: string) => {
  const creat = new Date(date);
  let year = creat.getFullYear();
  let day = String(creat.getDate()).padStart(2, "0");

  const month = () => {
    switch (String(creat.getMonth() + 1).padStart(2, "0")) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
    }
  };

  return `${month()} ${day}, ${year}`;
};
