import "./SideMenu.scss";
import { slide as Menu } from "react-burger-menu";
import { useMediaQuery } from "react-responsive";
import { useRecoilState, useRecoilValue } from "recoil";
import { t } from "i18next";
import { VFUN_IMAGE_DNS } from "utils/config/Config";
import { useEffect, useState } from "react";
import Language from "./Language";
import sideMenuList from "component/CombineMenu/Data/sideMenuList.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { isLoginState } from "recoil/userInfoAtom";
import MenuToggle from "./MenuToggle";
import { isVFUNListState } from "../recoil/recoil";
import VFUNGameList from "./VFUNGameList";
import CustomList from "./CustomList";
import { useLoginCheck } from "component/LoginModal/hook/useLoginCheck";
import { siteTypeState } from "recoil/commonUiAtom";

function SideMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const isLogin = useRecoilValue(isLoginState);
  const [inputToggle, setInputToggle] = useState(false);
  const [isVFUNList, setIsVFUNList] = useRecoilState(isVFUNListState);
  const isBilling = window.location.origin.includes("billing");
  const isNotLogin = useLoginCheck();
  const siteType = useRecoilValue(siteTypeState);

  const width768 = useMediaQuery({
    query: "screen and (max-width: 768px)"
  });

  const shouldRenderMenu = (menuCode: string) => {
    if (isLogin) {
      return menuCode !== "membership";
    } else {
      return menuCode !== "stamp";
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "auto";
  }, [isOpen]);

  useEffect(() => {
    if (isBilling) {
      setIsVFUNList(true);
    }
  }, [isBilling]);

  useEffect(() => {
    setIsVFUNList(siteType === "vfun" || siteType === "lounge");
  }, [siteType]);

  const onClickSignin = (menu: string) => {
    if (menu === "signin") {
      isNotLogin();
      return;
    }
  };

  return (
    <Menu
      pageWrapId={"page-wrap"}
      outerContainerId={"combineMenu"}
      onStateChange={(res) => setIsOpen(res.isOpen)}
      customBurgerIcon={<FontAwesomeIcon icon={faBars} color={"#fff"} />}
      customCrossIcon={<FontAwesomeIcon icon={faX} color={"#fff"} />}
      id="sideMenu"
    >
      {width768 && <Language />}
      {isBilling || siteType === "vfun" || siteType === "lounge" ? null : (
        <MenuToggle />
      )}
      <div className="short-cut">
        <p className="title">{t("short_cut")}</p>
        <ul>
          <li>
            <a href={process.env.REACT_APP_PLATFORM_DNS as string}>
              <img
                src={`${VFUN_IMAGE_DNS}/web/vfun/images/logo/vfun-logo.png`}
                alt="vfun logo"
              />
              <p>VFUN</p>
            </a>
          </li>
          <li>
            <a href={process.env.REACT_APP_LOUNGE_DNS as string}>
              <img
                src={`${VFUN_IMAGE_DNS}/web/vfun/images/logo/lounge-logo.png`}
                alt="lounge logo"
              />
              <p>
                VFUN
                <br />
                Lounge
              </p>
            </a>
          </li>
          <li>
            <a href={process.env.REACT_APP_GROUND_DNS as string}>
              <img
                src={`${VFUN_IMAGE_DNS}/web/vfun/images/logo/ground-logo.png`}
                alt="ground logo"
              />
              <p>
                VFUN
                <br />
                Ground
              </p>
            </a>
          </li>
        </ul>
      </div>

      {isVFUNList ? (
        <div
          className="y-scroll all-game-list"
          onScroll={(e: React.UIEvent<HTMLElement>) => {
            if (e.currentTarget.scrollTop !== 0) setInputToggle(false);
          }}
        >
          <VFUNGameList
            isVFUNList={isVFUNList}
            inputToggle={inputToggle}
            setInputToggle={setInputToggle}
          />
        </div>
      ) : (
        <div className="y-scroll" id="cont-wrap">
          <CustomList />
        </div>
      )}

      <div>
        {sideMenuList.data.map((i, idx) => {
          if (!shouldRenderMenu(i.menu_code)) {
            return;
          }

          return (
            <div
              className={sideMenuList.data.length - 1 === idx ? "" : "pb20"}
              key={i.menu_code}
            >
              <p className="sub-title">{i.menu_code}</p>
              <ul>
                {i.sub_menus.map((j) =>
                  j.menu_code === "inquire_for_non_member" && isLogin ? null : (
                    <li key={j.menu_code}>
                      {j.target_url === "" ? (
                        <div onClick={() => onClickSignin(j.menu_code)}>
                          {t(j.menu_code)}
                        </div>
                      ) : (
                        <a
                          href={`${process.env.REACT_APP_PLATFORM_DNS}${j.target_url}`}
                        >
                          {t(j.menu_code)}
                        </a>
                      )}
                    </li>
                  )
                )}
              </ul>
            </div>
          );
        })}
      </div>
    </Menu>
  );
}

export default SideMenu;
