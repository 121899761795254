import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import { BILLING_CHARGE_URL, VFUN_IMAGE_DNS } from "utils/config/Config";
import { redirectURL } from "utils/CommonFunc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { headersState, isLauncherState } from "recoil/userInfoAtom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { dropBoxToggleState } from "../recoil/recoil";
import { getGrade } from "component/CombineMenu/api/api";
import { serviceCodeState } from "recoil/commonUiAtom";

function Grade() {
  const headers = useRecoilValue(headersState);
  const service_code = useRecoilValue(serviceCodeState);
  const isLauncher = useRecoilValue(isLauncherState);
  const dropBoxToggle = useRecoilValue(dropBoxToggleState);

  const { isLoading, data } = useQuery(["grade"], () => getGrade(headers), {
    enabled: !!dropBoxToggle.profile
  });

  return !isLoading ? (
    data?.result === 1 ? (
      <img
        src={`${VFUN_IMAGE_DNS}/web/vfun/images/common/grade/bg-${
          data.data.split(".")[0]
        }.png`}
        alt="grade"
        onClick={() =>
          redirectURL(
            BILLING_CHARGE_URL(service_code, 1, false) as string,
            "_self",
            isLauncher
          )
        }
      />
    ) : null
  ) : (
    <FontAwesomeIcon icon={faSpinner} pulse style={{ marginLeft: "1rem" }} />
  );
}

export default Grade;
