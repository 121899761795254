import { atom } from "recoil";

export const dropBoxToggleState = atom<{
  profile: boolean;
  bell: boolean;
  lang: boolean;
}>({
  key: "dropBoxToggleState",
  default: {
    profile: false,
    bell: false,
    lang: false
  }
});

export const isAllReadState = atom<{ type: number; is: boolean }>({
  key: "isAllReadState",
  default: {
    type: 0,
    is: false
  }
});

export const isNewNotiState = atom<{
  vfun: boolean;
  lounge: boolean;
  ground: boolean;
}>({
  key: "isNewNotiState",
  default: {
    vfun: false,
    lounge: false,
    ground: false
  }
});

export const isVFUNListState = atom<boolean>({
  key: "isVFUNListState",
  default: false
});
