import { isLoginStateSelector } from "recoil/userInfoAtom";
import { t } from "i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { isLoginModalOpenState } from "recoil/commonUiAtom";

export const useLoginCheck = () => {
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const setIsLoginModalOpen = useSetRecoilState(isLoginModalOpenState);

  const isNotLogin = () => {
    if (isLoginState.isLauncher) {
      window.app.vfunRequestLogin();
    } else if (isLoginState.isMobile) {
      alert(t("please_login_use"));
      return;
    } else {
      setIsLoginModalOpen(true);
    }
  };

  return isNotLogin;
};
