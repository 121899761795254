import { atom } from "recoil";

export const inputValueState = atom<{
  id: string;
  password: string;
  otpNum: string;
}>({
  key: "inputValueState",
  default: { id: "", password: "", otpNum: "" }
});

export const isCertifiedState = atom<boolean>({
  key: "isCertifiedState",
  default: false
});
