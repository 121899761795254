import { atom } from "recoil";
import {
  createArrayAtom,
  createBooleanAtom,
  createStringAtom
} from "recoil/commonAtom";

export const isLoginModalOpenState = createBooleanAtom(
  "isLoginModalOpenState",
  false
);

//// 언어
export const langState = createStringAtom("langState", "English");
export const langCodeState = createStringAtom("langCodeState", "en");
export const userCountryState = createStringAtom("userCountryState", "");

interface LangList {
  lang: string;
  lang_code: string;
}
export const langListState = createArrayAtom<LangList>("langListState", []);

export const defaultLangState = atom<{ lang: string; lang_code: string }>({
  key: "defaultLangState",
  default: {
    lang: "",
    lang_code: ""
  }
});

export const serviceCodeState = createStringAtom("serviceCodeState", "vfun");
export const siteTypeState = createStringAtom("siteTypeState", "");
