import React, { useState } from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import axios from "axios";
import { dateFormType1 } from "utils/CommonFunc";
import { useCookies } from "react-cookie";
import { useSigninSetCookie } from "./hook/useSigninSetCookie";
import { snsSigninProcApi, SnsSignupCheckApi } from "./api/api";
import { inputValueState, isCertifiedState } from "./recoil/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { serviceCodeState } from "recoil/commonUiAtom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function FaceBookBtn({ onConfirm }: { onConfirm: () => void }) {
  const [cookies, setCookie] = useCookies();
  const signinSetCookie = useSigninSetCookie();
  const setIsCertified = useSetRecoilState(isCertifiedState);
  const setInputValue = useSetRecoilState(inputValueState);
  const service_code = useRecoilValue(serviceCodeState);
  const [isLoading, setIsLoading] = useState(false);

  const dialogParams = {
    redirect_uri: `${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_login`,
    response_type: "code",
    state: "facebookdirect"
  };

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_VFUN_FACEBOOK_OAUTH_APP_ID as string}
      fields="id,first_name,last_name,birthday,email"
      scope="public_profile,user_birthday,email"
      onProfileSuccess={(response) => {
        const signUpCheck = async () => {
          try {
            let res = await axios.get(
              SnsSignupCheckApi("Facebook", response.id || "")
            );
            if (res.data.result === 1) {
              const state = `type=Facebook,birthday=${
                response.birthday || ""
              },email=${response.email},first_name=${
                response.first_name || ""
              },last_name=${response.last_name || ""},id=${response.id}`;

              window.location.replace(
                `${
                  process.env.REACT_APP_PLATFORM_DNS
                }/membership/sns_login?state=${encodeURIComponent(state)}`
              );
            } else if (res.data.result === -98) {
              setIsLoading(true);
              try {
                let res = await axios.post(snsSigninProcApi, {
                  user_f_name: response.first_name || "",
                  user_l_name: response.last_name || "",
                  user_birth: dateFormType1(response.birthday) || "",
                  user_email: response.email,
                  receive_letter: "",
                  nickname: "",
                  user_id: response.id,
                  sns_type: "Facebook",
                  site_code: service_code
                });
                const result = res.data.result;
                if (result === 1) {
                  window.dispatchEvent(
                    new CustomEvent("loginValue", { detail: true })
                  );
                  signinSetCookie(res.data.data);
                  onConfirm();
                } else if (result === 4073) {
                  setInputValue((prev) => ({
                    ...prev,
                    id: res.data.data.user_id
                  }));
                  setIsCertified(true);
                }
              } catch (error) {
                console.log("facebook", error);
              } finally {
                setIsLoading(false);
              }
            }
          } catch (error) {
            console.log("fb signUp", error);
          }
        };
        signUpCheck();
      }}
      onFail={() => console.log("facebook login fail")}
      useRedirect={false}
      dialogParams={dialogParams}
      render={({ onClick }) => (
        <li onClick={onClick}>
          <div>
            <i className="icon fb" />
          </div>

          {isLoading ? <FontAwesomeIcon icon={faSpinner} pulse /> : "Facebook"}
        </li>
      )}
    />
  );
}

export default FaceBookBtn;
