import { useEffect, useState } from "react";
import QrArea from "./QrArea";
import "./LoginModal.scss";
import { t } from "i18next";
import LoginForm from "./LoginForm";
import FacebookBtn from "./FacebookBtn";
import AppleBtn from "./AppleBtn";
import GoogleBtn from "./GoogleBtn";
import { TextWrap } from "./ValidText";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userCountryState } from "recoil/commonUiAtom";
import { inputValueState, isCertifiedState } from "./recoil/recoil";
import { Modal } from "./LoginWrapStyle";
import OTPForm from "./OTPForm";

const FindIDURL = `${process.env.REACT_APP_PLATFORM_DNS}/membership/find_id`;
const FindPWURL = `${process.env.REACT_APP_PLATFORM_DNS}/membership/find_pw`;
const SIGNUP = `${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_signup_step_vfun?service_code=vfun`;
let duration = 2000;

const LoginDom = ({ onConfirm }: { onConfirm: () => void }) => {
  const [showModal, setShowModal] = useState(false);
  const [isFaid, setIsFaid] = useState(false);
  const [validText, setValidText] = useState("");
  const userCountry = useRecoilValue(userCountryState);
  const [isCertified, setIsCertified] = useRecoilState(isCertifiedState);
  const setInputValue = useSetRecoilState(inputValueState);

  const handleConfirm = () => {
    setShowModal(false);
    setTimeout(() => onConfirm(), 1);
  };

  useEffect(() => {
    setTimeout(() => setShowModal(true), 1);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFaid(false);
      setValidText("");
    }, duration + 1000);

    return () => clearTimeout(timer);
  }, [isFaid, duration]);

  useEffect(() => {
    if (!showModal) {
      setInputValue({ id: "", password: "", otpNum: "" });
      setIsCertified(false);
    }
  }, [showModal]);

  return (
    <Modal.Container showModal={showModal}>
      <div className="shadow" onClick={handleConfirm} />
      <Modal.Content>
        {isFaid && <TextWrap duration={duration}>{validText}</TextWrap>}
        {/*  */}
        {/* <QrArea onConfirm={handleConfirm} /> */}
        <div className="login-right" style={{ paddingLeft: "0px" }}>
          {isCertified ? (
            <OTPForm
              onConfirm={handleConfirm}
              setIsFaid={setIsFaid}
              setValidText={setValidText}
            />
          ) : (
            <div>
              <LoginForm
                onConfirm={handleConfirm}
                setIsFaid={setIsFaid}
                setValidText={setValidText}
              />

              <a href={SIGNUP} className="btn signup">
                <div>
                  Create a VFUN <span>Account</span>
                </div>
              </a>

              <ul className="sns-login">
                {userCountry !== "CN" && (
                  <>
                    <GoogleBtn onConfirm={handleConfirm} />
                    <FacebookBtn onConfirm={handleConfirm} />
                  </>
                )}

                <AppleBtn onConfirm={handleConfirm} />
              </ul>

              <div className="links">
                <a href={FindIDURL}>{t("find_id")}</a>
                <a href={FindPWURL}>{t("find_password")}</a>
              </div>
            </div>
          )}
        </div>
      </Modal.Content>
    </Modal.Container>
  );
};

interface LoginModalProps {
  onClose: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({ onClose }) => {
  return <LoginDom onConfirm={onClose} />;
};

export default LoginModal;
