import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { useSigninSetCookie } from "./hook/useSigninSetCookie";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { snsSigninProcApi, SnsSignupCheckApi } from "./api/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { inputValueState, isCertifiedState } from "./recoil/recoil";
import { serviceCodeState } from "recoil/commonUiAtom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  handleSuccess?: (data: any) => void;
  onConfirm: () => void;
}

function GoogleBtn(props: IProps) {
  const [cookies, setCookie] = useCookies();
  const googleSignInButton = useRef<HTMLDivElement>(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const signinSetCookie = useSigninSetCookie();
  const setIsCertified = useSetRecoilState(isCertifiedState);
  const setInputValue = useSetRecoilState(inputValueState);
  const service_code = useRecoilValue(serviceCodeState);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleSignIn = useCallback(
    (res: CredentialResponse) => {
      if (!res.clientId || !res.credential) return;
      // 로그인 정보 받아오는 코드 필요
      const userInfo = jwtDecode<JwtPayload | any>(res.credential);
      const signUpCheck = async () => {
        try {
          let res = await axios.get(SnsSignupCheckApi("Google", userInfo.sub));

          if (res.data.result === 1) {
            const state = `type=Google,birthday=,email=${
              userInfo.email || ""
            },first_name=${userInfo.given_name || ""},last_name=${
              userInfo.family_name || ""
            },id=${userInfo.sub}`;

            window.location.replace(
              `${
                process.env.REACT_APP_PLATFORM_DNS
              }/membership/sns_login?state=${encodeURIComponent(state)}`
            );
          } else if (res.data.result === -98) {
            setIsLoading(true);
            try {
              let res = await axios.post(snsSigninProcApi, {
                user_f_name: userInfo.given_name || "",
                user_l_name: userInfo.family_name || "",
                user_birth: "",
                user_email: userInfo.email || "",
                receive_letter: "",
                nickname: "",
                user_id: userInfo.sub,
                sns_type: "Google",
                site_code: service_code
              });
              const result = res.data.result;
              if (result === 1) {
                window.dispatchEvent(
                  new CustomEvent("loginValue", { detail: true })
                );
                signinSetCookie(res.data.data);
                props.onConfirm();
              } else if (result === 4073) {
                setInputValue((prev) => ({
                  ...prev,
                  id: res.data.data.user_id
                }));
                setIsCertified(true);
              }
            } catch (error) {
              console.log("facebook", error);
            } finally {
              setIsLoading(false);
            }
          }
        } catch (error) {
          console.log("fb signUp", error);
        }
      };
      signUpCheck();
      setScriptLoaded(false);
    },
    [props.handleSuccess]
  );

  const onClickGoogleBtn = () => {
    let element: HTMLElement = document.querySelector(
      '[aria-labelledby="button-label"]'
    ) as HTMLElement;

    if (element) {
      element.click();
    } else {
      console.error("Element not found.");
    }
  };

  useEffect(() => {
    if (
      typeof window === "undefined" ||
      !window.google ||
      !googleSignInButton.current
    ) {
      return;
    }
    const { google } = window;

    const initializeGoogle = () => {
      if (!google || scriptLoaded) return;

      setScriptLoaded(true);
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_VFUN_GOOGLE_OAUTH_CLIENT_ID as string,
        callback: handleGoogleSignIn,
        ux_mode: "popup"
      });

      const parent = document.getElementById("google-login-api");
      if (parent) {
        google.accounts.id.renderButton(parent, {
          type: "standard"
        });
      }
    };

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = initializeGoogle;
    script.async = true;
    script.id = "google-client-script";
    document.querySelector("body")?.appendChild(script);

    return () => {
      window.google?.accounts.id.cancel();
      document.getElementById("google-client-script")?.remove();
    };
  }, [handleGoogleSignIn, scriptLoaded]);

  return (
    <>
      <Btn id="google-login-api" ref={googleSignInButton} />

      <li onClick={onClickGoogleBtn}>
        <div>
          <i className="icon gg" />
        </div>

        {isLoading ? <FontAwesomeIcon icon={faSpinner} pulse /> : "Google"}
      </li>
    </>
  );
}

export default GoogleBtn;

const Btn = styled.div`
  opacity: 0;
  width: 80px !important;
  position: absolute;
  left: 0;
  display: inline-block;

  iframe {
    width: 80px !important;
  }
`;
