import React, { useEffect, Suspense, useCallback } from "react";
import { Route, RouterProvider } from "react-router-dom";
import { useCookies, withCookies } from "react-cookie";
import md5 from "md5";
import { useRecoilState, useSetRecoilState } from "recoil";
import "./App.scss";
import {
  juminState,
  isLoginState,
  ssoinfoState,
  userBirthState,
  userIdState,
  userNicknameState,
  userProfileKeyState,
  userProfileImgState,
  loginLoadingState
} from "recoil/userInfoAtom";
import { GET_MEMBERINFO_G, IMember, refreshTokenApi } from "./api/api";
import { Service_GL, VERIFY_KEY } from "utils/config/Config";
import WrapLoading from "component/loading/WrapLoading";
import { isLoginModalOpenState } from "recoil/commonUiAtom";
import axios from "axios";
import Pageroutes from "routers/PageRoutes";
import LoginModal from "component/LoginModal/LoginModal";
import LauncherInfo from "utils/LuncherInfo";
import LanguageState from "language/LanguageState";
import ResetInfo from "utils/ResetInfo";
import { useLogout } from "component/CombineMenu/hooks/useLogout";

function App() {
  const [isLogin, setIsLogin] = useRecoilState(isLoginState);
  const setUserID = useSetRecoilState(userIdState);
  const setSSOInfo = useSetRecoilState(ssoinfoState);
  const setJuminInfo = useSetRecoilState(juminState);
  const setUserBirth = useSetRecoilState(userBirthState);
  const setUserNickName = useSetRecoilState(userNicknameState);
  const setUserProfileKey = useSetRecoilState(userProfileKeyState);
  const setUserProfileImg = useSetRecoilState(userProfileImgState);
  const [isLoginModalOpen, setIsLoginModalOpen] = useRecoilState(
    isLoginModalOpenState
  );
  const [cookies, setCookie, removeCookie] = useCookies();
  const setLoginLoading = useSetRecoilState(loginLoadingState);
  const logout = useLogout();
  const deviceType = window.navigator.userAgent.split("::")[1];

  axios.defaults.withCredentials = true;

  const memberInfoProcess = async (userid: string, birthday: string) => {
    setLoginLoading(true);
    try {
      let res: any = await GET_MEMBERINFO_G({
        Ssoinfo: encodeURIComponent(""),
        Userid: userid,
        Userbirth: birthday
      });
      if (res.result === 1) {
        const userData: IMember = res;
        setUserID(userData.data.user_id);
        setSSOInfo(userData.sso_info);
        setUserBirth(userData.data.user_birthday);
        setUserNickName(userData.data.nickName || "");
        setUserProfileKey(userData.data.user_profile_key);
        setUserProfileImg(userData.data.profile_image_url || "");
        setIsLogin(true);
      } else if (res.result === 4010) {
        axios.post(refreshTokenApi).then((res) => {
          if (res.data.result === 1) {
            setCookie(
              process.env.REACT_APP_COOKIES_EXPIRED as string,
              res.data.data.expired_at,
              {
                path: "/",
                domain: ".valofe.com"
              }
            );
          }
        });
      } else {
        logout();
      }
    } catch (error) {
      console.log(error, "login");
    } finally {
      setLoginLoading(false);
    }
  };

  const valofe_process = useCallback(() => {
    if (Service_GL) {
      // 글로벌
      let cookies_userid = cookies["userid"];
      let cookies_birthday = cookies["birthday"];
      let expired_at = cookies[process.env.REACT_APP_COOKIES_EXPIRED as string];

      if (deviceType === "vfun-app") {
        return;
      }

      // expired_at !== undefined
      if (expired_at !== undefined) {
        memberInfoProcess(cookies_userid, cookies_birthday);
      }
    } else {
      // 한국
      let cookies_goonzu = cookies["goonzu"];
      if (cookies_goonzu === undefined) {
        setIsLogin(false);
      } else {
        let cookies_info = cookies_goonzu.split("&");
        if (cookies_info.length > 0) {
          let param: any = [];
          for (let i = 0; i < cookies_info.length; i++) {
            let key = cookies_info[i].split("=")[0];
            let value = cookies_info[i].split("=")[1];
            param[key] = value;
          }
          let jumin = cookies["jumin"];
          let ssoinfo =
            cookies[process.env.REACT_APP_SSO_INFO_COOKIE_NAME as string];
          let user_id = decodeURI(param["userid"]);
          let cookies_string = user_id + jumin + VERIFY_KEY;
          if (md5(cookies_string) === ssoinfo) {
            setUserID(user_id);
            setSSOInfo(ssoinfo);
            setJuminInfo(jumin);
            setIsLogin(true);
          } else {
            setIsLogin(false);
          }
        } else {
          setIsLogin(false);
        }
      }
    }
  }, [cookies[process.env.REACT_APP_COOKIES_EXPIRED as string]]);

  useEffect(() => {
    if (
      window.location.hostname.includes(".valofe.com") ||
      window.location.hostname.includes("localhost")
    ) {
      valofe_process();
    }
  }, [valofe_process]);

  useEffect(() => {
    const handleModalStatusChange = (event: any) => {
      const isOpen = event.detail;
      setIsLoginModalOpen(isOpen);
    };

    window.addEventListener("loginModalOpen", handleModalStatusChange);
    return () =>
      window.removeEventListener("loginModalOpen", handleModalStatusChange);
  }, []);

  if (deviceType === "vfun-app") {
    return null;
  }

  return (
    <Suspense fallback={<WrapLoading />}>
      <LauncherInfo />

      <ResetInfo />
      <LanguageState />
      <RouterProvider router={Pageroutes} />
      {isLoginModalOpen && (
        <LoginModal onClose={() => setIsLoginModalOpen(false)} />
      )}
    </Suspense>
  );
}

export default withCookies(App);
