import { faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import "./LoginForm.scss";
import { useEffect, useState } from "react";
import { inputValueState, isCertifiedState } from "./recoil/recoil";
import { useRecoilState, useSetRecoilState } from "recoil";
import axios from "axios";
import { ILoginData, verifyOtpApi } from "./api/api";
import { useSigninSetCookie } from "./hook/useSigninSetCookie";

type props = {
  onConfirm: () => void;
  setIsFaid: React.Dispatch<React.SetStateAction<boolean>>;
  setValidText: React.Dispatch<React.SetStateAction<string>>;
};

export default function OTPForm({ onConfirm, setIsFaid, setValidText }: props) {
  const [inputValue, setInputValue] = useRecoilState(inputValueState);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const setIsCertified = useSetRecoilState(isCertifiedState);
  const signinSetCookie = useSigninSetCookie();

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue((prev) => ({
      ...prev,
      otpNum: e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
    }));
  };

  useEffect(() => {
    setIsValid(inputValue.otpNum.length !== 0);
  }, [inputValue]);

  const onClickLogin = async () => {
    if (!isValid) {
      setIsFaid(true);
      setValidText(`Please Input your otp number.`);
      return;
    }

    if (isValid) {
      setIsLoading(true);
      try {
        await axios
          .post(
            verifyOtpApi,
            {
              user_id: inputValue.id,
              otp: inputValue.otpNum
            },
            {
              headers: { device: "web" }
            }
          )
          .then((res) => {
            const data: ILoginData = res.data;
            if (data.result === 1) {
              window.dispatchEvent(
                new CustomEvent("loginValue", { detail: true })
              );
              signinSetCookie(data.data);
              onConfirm();
            } else if (data.result === 4076) {
              setIsFaid(true);
              setValidText(`${t("번역 otp 인증에 실패하였습니다")}`);
              setIsLoading(false);
            } else if (data.result === 4075) {
              setIsFaid(true);
              setValidText(`${t("번역 otp 번호가 올바르지 않습니다.")}`);
              setIsLoading(false);
            } else {
              setIsFaid(true);
              setValidText(`${t("otp가 설정되어 있지 않습니다.")}`);
              setIsLoading(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onClickBack = () => {
    setIsCertified(false);
    setInputValue({ id: "", password: "", otpNum: "" });
  };

  return (
    <div id="loginForm">
      <div className="form-title">
        <FontAwesomeIcon icon={faArrowLeft} onClick={onClickBack} />
        2단계 인증
      </div>
      <div className="input-area">
        <label htmlFor="otp" className="hidden" />
        <input
          type="text"
          name="otp"
          minLength={6}
          maxLength={6}
          value={inputValue.otpNum}
          onChange={onValueChange}
          placeholder={t("번역 인증 번호를 입력하세요")}
          autoFocus
        />
      </div>
      <p className="mt20">
        안전한 로그인을 위해 2단계 인증을 진행합니다. <br />
        APP을 통해 인증 번호를 확인하세요.
      </p>
      <p className="mt20 fs12">
        QR 로그인은 2단계 인증없이 빠르고 간편하게 로그인이 가능합니다.
      </p>
      <button
        type="button"
        className="btn purple"
        disabled={isLoading}
        onClick={onClickLogin}
      >
        {isLoading ? <FontAwesomeIcon icon={faSpinner} pulse /> : t("login")}
      </button>
    </div>
  );
}
