import axios from "axios";
import { logoutApi } from "api/api";
import { useCookies } from "react-cookie";
import { redirectURL } from "utils/CommonFunc";
import { Service_CN } from "utils/config/Config";

export const useLogout = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const isLauncher =
    window.navigator.userAgent.split("::")[1] === "vfun-launcher";

  const logout = async () => {
    if (Service_CN) {
      redirectURL(
        `${process.env.REACT_APP_PLATFORM_DNS}/logout?redirect=http%3A%2F%2Fc9.bbs.xiyouxi.com%2F`,
        "_self",
        isLauncher
      );
    } else {
      if (isLauncher) {
        window.app.vfunRequestLogout();
        redirectURL(
          `${process.env.REACT_APP_PLATFORM_DNS}`,
          "_self",
          isLauncher
        );

        return;
      }

      try {
        await axios.post(logoutApi).then((res) => {
          if (res.data.result === 1) {
            window.localStorage.clear();
            window.dispatchEvent(
              new CustomEvent("loginValue", { detail: false })
            );
            Object.keys(cookies).forEach((keys) => {
              if (keys !== "locale") {
                removeCookie(keys, {
                  path: "/",
                  domain: process.env.REACT_APP_COOKIE_DOMAIN
                });
              }
            });

            redirectURL(window.location.href, "_self", isLauncher);
          }
        });
      } catch (error) {
        console.log(error, "logout");
      }
    }
  };

  return logout;
};
