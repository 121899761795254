//// 서비스 관련 공통
export const Service_GL: boolean =
  process.env.REACT_APP_SERVICE_TYPE === "global";
export const Service_CN =
  process.env.REACT_APP_COOKIE_DOMAIN === ".xiyouxi.com";

//// 사이트 정보 키
export const VERIFY_KEY: string = "ehG.NSdz.qoffhvm!@";

//// UI
export const VFUN_IMAGE_DNS: string = "https://file.qijisoft.com/Valofe_file";

//// 이동 URL
export const BILLING_CHARGE_URL = (
  service_code: string,
  type: number,
  isLauncher: boolean
) => {
  let url = "";
  let urlType = "";

  // type === 1 purchase 구매
  // 2 history 충전내역
  // 3 purchase_history 사용내역

  if (Service_GL) {
    switch (type) {
      case 1:
        urlType = "purchase";
        break;
      case 2:
        urlType = "history";
        break;
      case 3:
        urlType = "purchase_history";
    }

    url = `/api/common/redirect/billing?service_code=${service_code}&type=${urlType}`;
  } else {
    url = "/mycash/charge_info.asp";
  }

  if (isLauncher) {
    window.app.vfunAuthWebExecute(process.env.REACT_APP_VFUN_API_DNS + url);
    return;
  }

  return process.env.REACT_APP_VFUN_API_DNS + url;
};

export const PRIVACY_POLICY_URL = (service_code: string) =>
  `${process.env.REACT_APP_PLATFORM_DNS}/customer/g_pc_privacy?service_code=${service_code}&language=en`;

export const TERMS_OF_AGREEMENT_URL = (service_code: string) =>
  `${process.env.REACT_APP_PLATFORM_DNS}/customer/g_pc_terms?service_code=${service_code}&language=en`;
