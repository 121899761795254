import { getLanguage, ILang } from "api/api";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  defaultLangState,
  langCodeState,
  langListState,
  langState,
  serviceCodeState,
  userCountryState
} from "recoil/commonUiAtom";
import { Service_GL } from "utils/config/Config";

export default function LanguageState() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [langList, setLangList] = useRecoilState(langListState);
  const [langCode, setLangCode] = useRecoilState(langCodeState);
  const [defaultLang, setDefaultLang] = useRecoilState(defaultLangState);
  const setUserCountry = useSetRecoilState(userCountryState);
  const setLang = useSetRecoilState(langState);
  const { i18n } = useTranslation();
  const service_code = useRecoilValue(serviceCodeState);

  // 언어
  const { isLoading: langLoading, data: langData } = useQuery<ILang>(
    ["lang"],
    () => getLanguage(service_code, langCode)
  );

  useEffect(() => {
    if (langData?.result === 1) {
      setLangList(langData.data);
      setDefaultLang({
        lang: langData.default_language.lang,
        lang_code: langData.default_language.lang_code
      });
      setUserCountry(langData.country);
    }
  }, [langData]);

  // 언어 쿠키 참조
  useEffect(() => {
    if (Service_GL) {
      // 글로벌 일 경우
      if (
        !(
          cookies["locale"] === undefined ||
          cookies["locale"] === null ||
          cookies["locale"] === ""
        )
      ) {
        for (let i = 0; i < langList.length; i++) {
          if (langList[i].lang === cookies["locale"]) {
            setCookie("locale", cookies["locale"], {
              path: "/",
              domain: ".valofe.com"
            });
            setLang(langList[i].lang_code);
            setLangCode(cookies["locale"]);
            i18n.changeLanguage(cookies["locale"]);
          }
        }
      } else {
        setCookie("locale", defaultLang.lang, {
          path: "/",
          domain: ".valofe.com"
        });
        setLang(defaultLang.lang_code);
        setLangCode(defaultLang.lang);
        i18n.changeLanguage(defaultLang.lang);
      }
    } else {
      setCookie("locale", "ko", { path: "/", domain: ".valofe.com" });
      setLang("한국어");
      setLangCode("ko");
      i18n.changeLanguage("ko");
    }
  }, [langList, langData]);

  return null;
}
