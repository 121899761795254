import {
  faBell as fullbell,
  faGamepad
} from "@fortawesome/free-solid-svg-icons";
import "./CombineMenu.scss";
import SideMenu from "./components/SideMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Language from "./components/Language";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import Notification from "./components/Notification";
import Profile from "./components/Profile";
import { Link, useLocation } from "react-router-dom";
import { Service_GL, VFUN_IMAGE_DNS } from "utils/config/Config";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  headersState,
  headersType,
  isLoginStateSelector,
  loginLoadingState
} from "recoil/userInfoAtom";
import { t } from "i18next";
import axios from "axios";
import {
  dropBoxToggleState,
  isAllReadState,
  isNewNotiState
} from "./recoil/recoil";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { ILauncherData, launcherStartApi } from "api/api";
import { getNewNoti } from "./api/api";
import UserProfileImg from "component/UserProfileImg";
import { isSafari } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { useLoginCheck } from "component/LoginModal/hook/useLoginCheck";
import { serviceCodeState, siteTypeState } from "recoil/commonUiAtom";

const onClickLauncherBtn = async (
  service_code: string,
  headers: headersType
) => {
  // if (isSafari) {
  //   alert("window에서 지원합니다.");
  //   return;
  // }

  const failureTimeout = setTimeout(() => {
    // 실패시 코드

    alert(t("need_install_launcher"));
    window.location.replace(
      `${process.env.REACT_APP_PLATFORM_DNS}/game/download`
    );
  }, 1000);

  window.addEventListener("blur", () => {
    clearTimeout(failureTimeout);
  });

  const link = document.createElement("a");

  try {
    let res = await axios.post(
      launcherStartApi,
      { service_code: service_code },
      {
        headers: { ...headers }
      }
    );
    const data: ILauncherData = res.data.data;
    if (res.data.result === 1) {
      link.href = data.game_run_url;
    } else if (res.data.result === -97) {
      link.href = data.protocol;
    } else {
      console.log("왕실패");
    }
  } catch (error) {
    console.log("launcher", error);
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function CombineMenu() {
  const [dropBoxToggle, setDropBoxToggle] = useRecoilState(dropBoxToggleState);
  const [isNewNoti, setIsNewNoti] = useRecoilState(isNewNotiState);
  const isLoginState = useRecoilValue(isLoginStateSelector);
  const headers = useRecoilValue(headersState);
  const service_code = useRecoilValue(serviceCodeState);
  const location = useLocation();
  const isAllRead = useRecoilValue(isAllReadState);
  const siteType = useRecoilValue(siteTypeState);
  const isNotLogin = useLoginCheck();
  const loginLoading = useRecoilValue(loginLoadingState);

  const width360 = useMediaQuery({
    query: "screen and (max-width: 360px)"
  });

  const { isLoading, data } = useQuery(
    ["new-noti", isAllRead.is],
    () => getNewNoti(headers),
    {
      enabled: isLoginState.isLogIn,
      onSuccess: (data) => {
        if (data?.result === 1) {
          setIsNewNoti({
            vfun: data.data.non_read[0].count !== 0,
            lounge: data.data.non_read[1].count !== 0,
            ground: data.data.non_read[2].count !== 0
          });
        }
      }
    }
  );

  const isNew = isNewNoti.ground || isNewNoti.lounge || isNewNoti.vfun;
  const logo = `${VFUN_IMAGE_DNS}/${
    width360
      ? `web/vfun/images/logo/${
          siteType === "game" ? "vfun" : siteType
        }-logo.png`
      : `web/vfun-ko/images/logo/logo-${
          siteType === "game" ? "vfun" : siteType
        }.png`
  }`;
  const siteUrl =
    siteType === "ground"
      ? process.env.REACT_APP_GROUND_DNS
      : siteType === "lounge"
      ? process.env.REACT_APP_LOUNGE_DNS
      : process.env.REACT_APP_PLATFORM_DNS;

  const onClickBellBtn = () => {
    if (dropBoxToggle.profile || dropBoxToggle.lang) {
      setDropBoxToggle({ profile: false, bell: true, lang: false });
    } else {
      setDropBoxToggle((prev) => ({ ...prev, bell: !prev.bell }));
    }
  };

  const onClickProfileBtn = () => {
    if (loginLoading) {
      return;
    }

    if (!isLoginState.isLogIn) {
      if (isLoginState.isLauncher) {
        window.app.vfunRequestLogin();
      }
      isNotLogin();
      return;
    }

    if (dropBoxToggle.bell || dropBoxToggle.lang) {
      setDropBoxToggle({ profile: true, bell: false, lang: false });
    } else {
      setDropBoxToggle((prev) => ({ ...prev, profile: !prev.profile }));
    }
  };

  useEffect(() => {
    setDropBoxToggle({ profile: false, bell: false, lang: false });
  }, [location.pathname]);

  return (
    <div id="combineMenu">
      <SideMenu />

      {siteType !== "" && (
        <div className="logo-area">
          <a href={siteUrl}>
            <img src={logo} alt="logo" />
            {siteType !== "ground" && <span>{Service_GL ? "GL" : "KR"}</span>}
          </a>
        </div>
      )}

      <ul className="right-area">
        {!isLoginState.isLauncher && (
          <li
            className="launcher-btn tab"
            onClick={() => onClickLauncherBtn(service_code, headers)}
          >
            {t("run_launcher")}
            <FontAwesomeIcon icon={faGamepad} />
          </li>
        )}

        <li className="lang tab">
          <Language />
        </li>
        {isLoginState.isLogIn && (
          <li className="tab">
            {isNew && (
              <div className="dot-area">
                <span />
              </div>
            )}
            <FontAwesomeIcon
              icon={isNew ? fullbell : faBell}
              onClick={onClickBellBtn}
              bounce={isNew}
            />
            <Notification />
          </li>
        )}

        <li className="tab">
          <div onClick={onClickProfileBtn}>
            <UserProfileImg />
          </div>
          <Profile />
        </li>
      </ul>
    </div>
  );
}

export default CombineMenu;
