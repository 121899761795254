import {
  faEye,
  faEyeSlash,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { useEffect, useState } from "react";
import "./LoginForm.scss";
import { useSigninSetCookie } from "./hook/useSigninSetCookie";
import axios from "axios";
import { ILoginData, loginApi } from "./api/api";
import { inputValueState, isCertifiedState } from "./recoil/recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { siteTypeState } from "recoil/commonUiAtom";

type props = {
  onConfirm: () => void;
  setIsFaid: React.Dispatch<React.SetStateAction<boolean>>;
  setValidText: React.Dispatch<React.SetStateAction<string>>;
};

export default function LoginForm({
  onConfirm,
  setIsFaid,
  setValidText
}: props) {
  const [inputValue, setInputValue] = useRecoilState(inputValueState);
  const [isEyeOpen, setIsEyeOpen] = useState(false);
  const [isValid, setIsValid] = useState({
    id: false,
    password: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const setIsCertified = useSetRecoilState(isCertifiedState);

  const signinSetCookie = useSigninSetCookie();
  const siteType = useRecoilValue(siteTypeState);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  useEffect(() => {
    setIsValid({
      id: inputValue.id.length !== 0,
      password: inputValue.password.length !== 0
    });
  }, [inputValue]);

  const signInCheck = async () => {
    if (!isValid.id) {
      setIsFaid(true);
      setValidText(`Please Input your ID`);
      return;
    }

    if (!isValid.password) {
      setIsFaid(true);
      setValidText(`Please Input your password.`);
      return;
    }

    if (isValid.id && isValid.password) {
      setIsLoading(true);
      try {
        await axios
          .post(
            loginApi,
            {
              service_code: "vfun",
              input_user_id: inputValue.id,
              input_user_password: inputValue.password
            },
            {
              headers: { device: "web" }
            }
          )
          .then((res) => {
            const data: ILoginData = res.data;
            if (data.result === 1 || data.result === -95) {
              window.dispatchEvent(
                new CustomEvent("loginValue", { detail: true })
              );
              signinSetCookie(data.data);
              onConfirm();

              if (siteType === "game") {
                window.location.reload();
              }
            } else if (data.result === 4073) {
              setIsCertified(true);
            } else if (data.result === -88) {
              setIsFaid(true);
              setValidText(`차단된 아이피 로그인 시도`);
              setIsLoading(false);
            } else {
              setIsFaid(true);
              setValidText(`${t("id_or_pw_invalid")}`);
              setIsLoading(false);
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signInCheck();
  };

  return (
    <form id="loginForm" onSubmit={onSubmit}>
      <div className="form-title">{t("login")}</div>
      <div className="input-area">
        <label htmlFor="id" className="hidden" />
        <input
          type="text"
          name="id"
          value={inputValue.id}
          onChange={onValueChange}
          placeholder={t("id")}
          autoFocus
        />
      </div>
      <div className="input-area">
        <label htmlFor="password" className="hidden" />
        <input
          type={isEyeOpen ? "text" : "password"}
          name="password"
          value={inputValue.password}
          onChange={onValueChange}
          placeholder={t("login_user_password_desc")}
        />
        <FontAwesomeIcon
          icon={isEyeOpen ? faEye : faEyeSlash}
          onClick={() => setIsEyeOpen((prev) => !prev)}
        />
      </div>

      <button type="submit" className="btn purple" disabled={isLoading}>
        {isLoading ? <FontAwesomeIcon icon={faSpinner} pulse /> : t("login")}
      </button>
    </form>
  );
}
