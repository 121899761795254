import axios from "axios";
import { langCodeState } from "recoil/commonUiAtom";
import { useRecoilValue } from "recoil";
import { useInfiniteQuery } from "react-query";
import { headersState } from "recoil/userInfoAtom";
import { dropBoxToggleState, isAllReadState } from "../recoil/recoil";
import { BASE_PATH } from "api/api";
import { Service_GL } from "utils/config/Config";

export const useInfiniteScrollQuery = (type: number) => {
  const langCode = useRecoilValue(langCodeState);
  const headers = useRecoilValue(headersState);
  const dropBoxToggle = useRecoilValue(dropBoxToggleState);
  const isAllRead = useRecoilValue(isAllReadState);

  const getNotiApi = async ({ pageParam = 1 }) => {
    const res = await axios.get(
      `${BASE_PATH}/api/vfun/noti?lang=${langCode}&type=${type}&page=${pageParam}`,
      { headers: { ...headers } }
    );

    return {
      result: res.data.result,
      boardpage: res.data.result === 1 ? res.data.data.noti.data : "",
      currentpage: res.data.result === 1 ? res.data.data.noti.current_page : 0,
      total: res.data.result === 1 ? res.data.data.noti.total : 0,
      isLast:
        res.data.result === 1
          ? res.data.data.noti.current_page === res.data.data.noti.last_page
            ? true
            : false
          : false
    };
  };

  const {
    data: getBoard,
    fetchNextPage: getNextPage,
    isSuccess: getBoardIsSuccess,
    hasNextPage: getNextPageIsPossible
  } = useInfiniteQuery([`nf-list-${type}`, langCode, isAllRead], getNotiApi, {
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.isLast) {
        return lastPage.currentpage + 1;
      }
      return undefined;
    },
    enabled: !!dropBoxToggle.bell && Service_GL,
    onSuccess: (data) => {
      if (data.pages[0].result === -99) {
        window.location.reload();
      }
    }
  });

  return { getBoard, getNextPage, getBoardIsSuccess, getNextPageIsPossible };
};
