import React from "react";
import "language/i18n";
import CombineMenu from "component/CombineMenu/CombineMenu";
import Footer from "component/Footer/Footer";

function Main() {
  return (
    <div>
      <CombineMenu />
      {/* <div id="wrapper">{props.children}</div> */}
      {/* <Footer /> */}
    </div>
  );
}

export default Main;
