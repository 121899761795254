import { BASE_PATH } from "api/api";
import axios from "axios";
import { headersType } from "recoil/userInfoAtom";

// 지갑, 코인 (gcoin)
interface IGCoin {
  result: number;
  data: {
    bonus_cash: number;
    real_cash: number;
    return_code: any;
    point: number;
  };
}

export const getGcoin = (service_code: string, headers: headersType) =>
  axios
    .get(`${BASE_PATH}/api/vfun/gcoin/balance/${service_code}`, {
      headers: headers
    })
    .then((res) => {
      if (res.status === 200) return res.data as IGCoin;
    });

export const getGrade = (headers: headersType) =>
  axios
    .get(`${BASE_PATH}/api/vfun/billingGrade`, {
      headers: headers
    })
    .then((res) => {
      if (res.status === 200)
        return res.data as { data: string; result: number };
    });

export const getTargetUrl = (idx: number, headers: headersType, type: number) =>
  axios.get(`${BASE_PATH}/api/vfun/noti_click?noti_idx=${idx}&type=${type}`, {
    headers: headers
  });

export const putAllRead = (type: number) =>
  `${BASE_PATH}/api/vfun/noti_all_read_noti?type=${type}`;

type getNewNoti = {
  data: { non_read: { count: number; type: number }[] };
  result: number;
};

export const getNewNoti = (headers: headersType) =>
  axios
    .get(`${BASE_PATH}/api/vfun/new_noti`, {
      headers: headers
    })
    .then((res) => {
      if (res.status === 200) return res.data as getNewNoti;
    });
