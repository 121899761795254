import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useTranslation, withTranslation } from "react-i18next";
import { redirectURL } from "utils/CommonFunc";
import { BILLING_CHARGE_URL, Service_GL } from "utils/config/Config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { headersState, isLauncherState } from "recoil/userInfoAtom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { dropBoxToggleState } from "../recoil/recoil";
import { getGcoin } from "component/CombineMenu/api/api";
import { serviceCodeState } from "recoil/commonUiAtom";

function Wallet() {
  const { t } = useTranslation();
  const headers = useRecoilValue(headersState);
  const [gcoin, setGcoin] = useState(0);
  const isLauncher = useRecoilValue(isLauncherState);
  const dropBoxToggle = useRecoilValue(dropBoxToggleState);
  const service_code = useRecoilValue(serviceCodeState);

  const { isLoading, data } = useQuery(
    ["gcoin"],
    () => getGcoin("vfun", headers),
    { enabled: !!dropBoxToggle.profile }
  );

  useEffect(() => {
    if (data?.result !== undefined && data.result === 1) {
      if (Service_GL) {
        setGcoin(data.data.bonus_cash + data.data.real_cash);
      } else {
        if (data.data.return_code[0] === "0000") {
          setGcoin(data.data.bonus_cash + data.data.real_cash);
        }
      }
    } else {
      setGcoin(0);
    }
  }, [data]);

  return (
    <ul>
      <li className="gray-box row-flex">
        <p>{t("gcoins")}</p>
        <div>
          {!isLoading ? (
            <p>{gcoin.toLocaleString()}</p>
          ) : (
            <FontAwesomeIcon icon={faSpinner} pulse />
          )}
          <button
            onClick={() =>
              redirectURL(
                BILLING_CHARGE_URL(service_code, 1, false) as string,
                "_self",
                isLauncher
              )
            }
            className="purple btn"
          >
            {t("charge")}
          </button>
        </div>
      </li>
      <li className="gray-box row-flex">
        <p>Point</p>
        <div>
          {!isLoading ? (
            <p>
              {data?.result === 1
                ? Number(data.data.point).toLocaleString()
                : 0}
            </p>
          ) : (
            <FontAwesomeIcon icon={faSpinner} pulse />
          )}
          <a
            href={`${process.env.REACT_APP_PLATFORM_DNS}/stamp/my_stamp`}
            className="purple btn"
          >
            GET
          </a>
        </div>
      </li>
    </ul>
  );
}

export default React.memo(withTranslation()(Wallet));
