import styled, { keyframes } from "styled-components";

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const TextWrap = styled.div<{ duration: number }>`
  border-radius: 8px;
  background: rgba(34, 34, 34, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  max-width: 90%;
  margin: 0 auto;
  padding: 30px 64px;
  color: #fff;
  box-sizing: border-box;
  z-index: 1;

  animation: ${fadeOut} 2s forwards;
  animation-delay: ${({ duration }) => duration / 1000}s;
`;
