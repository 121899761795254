import React, { useState } from "react";
import AppleLogin from "react-apple-login";
import { useCookies } from "react-cookie";
import axios from "axios";
import { snsSigninProcApi, SnsSignupCheckApi } from "./api/api";
import { useSigninSetCookie } from "./hook/useSigninSetCookie";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { inputValueState, isCertifiedState } from "./recoil/recoil";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { serviceCodeState } from "recoil/commonUiAtom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AppleBtn({ onConfirm }: { onConfirm: () => void }) {
  const [cookies, setCookie] = useCookies();
  const signinSetCookie = useSigninSetCookie();
  const setIsCertified = useSetRecoilState(isCertifiedState);
  const setInputValue = useSetRecoilState(inputValueState);
  const service_code = useRecoilValue(serviceCodeState);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AppleLogin
      clientId={process.env.REACT_APP_VFUN_APPLE_BUNDLE_ID as string}
      redirectURI={`${
        window.location.protocol + "//" + window.location.hostname
      }/`}
      responseType={"code"}
      usePopup={true}
      responseMode={"query"}
      scope="name email"
      callback={(data) => {
        // if (data.error) return;
        if (data !== undefined || data !== null) {
          const userInfo = jwtDecode<JwtPayload | any>(
            data.authorization.id_token
          );

          const signUpCheck = async () => {
            try {
              let res = await axios.get(
                SnsSignupCheckApi("Apple", userInfo.sub)
              );
              if (res.data.result === 1) {
                const state = `type=Apple,birthday=,email=${userInfo.email},first_name=,last_name=,id=${userInfo.sub}`;

                window.location.replace(
                  `${
                    process.env.REACT_APP_PLATFORM_DNS
                  }/membership/sns_login?state=${encodeURIComponent(state)}`
                );
              } else if (res.data.result === -98) {
                setIsLoading(true);
                try {
                  let res = await axios.post(snsSigninProcApi, {
                    user_f_name: "",
                    user_l_name: "",
                    user_birth: "",
                    user_email: userInfo.email,
                    receive_letter: "",
                    nickname: "",
                    user_id: userInfo.sub,
                    sns_type: "Apple",
                    site_code: service_code
                  });
                  const result = res.data.result;
                  if (result === 1) {
                    window.dispatchEvent(
                      new CustomEvent("loginValue", { detail: true })
                    );
                    signinSetCookie(res.data.data);
                    onConfirm();
                  } else if (data.result === 4073) {
                    setInputValue((prev) => ({
                      ...prev,
                      id: res.data.data.user_id
                    }));
                    setIsCertified(true);
                  }
                } catch (error) {
                  console.log("applr", error);
                } finally {
                  setIsLoading(false);
                }
              }
            } catch (error) {
              console.log("ap signUp", error);
            }
          };
          signUpCheck();
        }
      }}
      render={({ onClick }) => {
        return (
          <li onClick={onClick}>
            <div>
              <i className="icon ap" />
            </div>

            {isLoading ? <FontAwesomeIcon icon={faSpinner} pulse /> : "Apple"}
          </li>
        );
      }}
    />
  );
}

export default AppleBtn;
