import { useLogout } from "component/CombineMenu/hooks/useLogout";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  isLoginModalOpenState,
  langCodeState,
  langState,
  siteTypeState
} from "recoil/commonUiAtom";
import {
  isLauncherState,
  isLoginState,
  isMobileAppState,
  loginLoadingState
} from "recoil/userInfoAtom";
import { redirectURL } from "./CommonFunc";

export default function LauncherInfo() {
  const deviceType = window.navigator.userAgent.split("::")[1];
  const [isLauncher, setIsLauncher] = useRecoilState(isLauncherState);
  const setIsMobileApp = useSetRecoilState(isMobileAppState);
  const [cookies, setCookie, removeCookie] = useCookies();
  const setLang = useSetRecoilState(langState);
  const setLangCode = useSetRecoilState(langCodeState);
  const { i18n } = useTranslation();
  const setIsLoginModalOpen = useSetRecoilState(isLoginModalOpenState);
  const logout = useLogout();
  const isLogin = useRecoilValue(isLoginState);
  const siteType = useRecoilValue(siteTypeState);
  const loginLoading = useRecoilValue(loginLoadingState);

  // 런처 여부
  useEffect(() => {
    if (deviceType === "vfun-launcher") {
      setIsLauncher(true);
    } else if (deviceType === "vfun-app") {
      setIsMobileApp(true);
    }
  }, [deviceType]);

  // 런처 로그인, 아웃
  useEffect(() => {
    if (isLauncher) {
      window.app.vfunSitInitFinish();
    }
    window.vfunLogin = function () {
      alert("Please close the VFUN launcher and restart it for an update.");
      return;
    };
    window.vfunLogout = function () {
      logout();
    };
    window.vfunOuterLogin = function (
      refreshToken: string,
      service_code: string
    ) {
      if (service_code === "vfun") {
        window.location.reload();
      } else {
        redirectURL(
          `/library?service_code=${service_code}&section=home`,
          "_self",
          isLauncher
        );
      }
    };
    window.vfunGameDetailPage = function (service_code: string) {
      redirectURL(
        `/library?service_code=${service_code}&section=home`,
        "_self",
        isLauncher
      );
    };
    window.vfunLangChange = function (
      lang: string,
      lang_code: string,
      vfunLauncherLang: string
    ) {
      setCookie("locale", lang, { path: "/", domain: ".valofe.com" });
      setCookie("vfunLauncherLocale", vfunLauncherLang, {
        path: "/",
        domain: ".valofe.com"
      });
      i18n.changeLanguage(lang);
      setLang(lang_code);
      setLangCode(lang);
    };
  }, []);

  const getRetValue = () => {
    try {
      const urlObj = new URL(window.location.href); // URL 객체 생성
      return urlObj.searchParams.get("ret"); // 'ret' 파라미터 값 추출
    } catch (error) {
      console.error("유효하지 않은 URL입니다.", error);
      return null;
    }
  };

  // useEffect(() => {
  //   const expired = cookies[process.env.REACT_APP_COOKIES_EXPIRED as string];

  //   if (deviceType !== "vfun-launcher") {
  //     if (expired === undefined) {
  //       logout();
  //       console.log("/");
  //     }
  //   }
  // }, [window.location.href]);

  // 빌링
  useEffect(() => {
    if (siteType === "vfun" && !isLogin) {
      if (
        getRetValue() !== "" &&
        getRetValue() !== null &&
        !(deviceType === "vfun-launcher" || deviceType === "vfun-app")
      ) {
        setIsLoginModalOpen(true);
      }
    }
  }, [siteType, isLogin]);

  return null;
}
