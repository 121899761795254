import { BASE_PATH } from "api/api";
import axios from "axios";

export interface ILoginData {
  data: {
    birthday: string;
    check_email: string;
    email: string;
    member_type: string;
    service_code: string;
    sso_info: string;
    sso_info_new: string;
    user_id: string;
    SSOKey: number;
    gender: string;
    country: string;
    auth: number;
    user_profile_key: string;
    expired_at: number;
  };
  result: number;
}

export const loginApi = `${BASE_PATH}/api/vfun/login`;

export const SnsSignupCheckApi = (sns_type: string, user_id: string) =>
  `${BASE_PATH}/api/vfun/sns_signup_check?sns_type=${sns_type}&user_id=${user_id}`;

export const snsSigninProcApi = `${BASE_PATH}/api/vfun/sns_signin_proc`;

export const verifyOtpApi = `${BASE_PATH}/api/vfun/otp/verify_otp`;

type IgetQr = {
  result: number;
  msg: string;
  data: {
    authorize_url: string;
    callback_url: string;
    union_id: string;
    expire_time: string;
    qr_base64_data: string;
  };
};

export const getQrApi = (Device: string, DeviceId: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/qrcode_signin_proc`, {
      headers: { Device: Device, "Device-Id": DeviceId }
    })
    .then((res) => {
      if (res.status === 200) return res.data as IgetQr;
    });
