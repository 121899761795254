import { BASE_PATH } from "api/api";
import axios from "axios";

// allgame
export interface IAllGameData {
  game_genre: string;
  game_title: string;
  game_type: "PC" | "Android, iOS";
  idx: number;
  service_code: string;
  web_url: string;
  release_at: string;
}

interface IAllGame {
  data: IAllGameData[];
  result: number;
}

export const getAllGames = (langCode: string) =>
  axios
    .get(`${BASE_PATH}/api/vfun/allgamelist?lang=${langCode}`)
    .then((res) => {
      if (res.status === 200) return res.data as IAllGame;
    });

// customList
type cont = {
  title: string;
  type: string;
  url: string;
};

export type oneDeps = {
  sub: [];
  title: string;
  type: string;
  url: string;
};

type twoDeps = {
  sub: cont[];
  title: string;
};

type menuType = oneDeps | twoDeps;

type getCustom = {
  data: {
    game_logo: string;
    game_title: string;
    menu: menuType[];
    title_type: "logo" | "title";
    title_url: string;
  };
  result: number;
};

export const getCustomList = (service_code: string, langCode: string) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/game_sub_menu?service_code=${service_code}&lang=${langCode}`
    )
    .then((res) => {
      if (res.status === 200) return res.data as getCustom;
    });
