import axios from "axios";
import { VFUN } from "../utils/config/request";
import { headersType } from "recoil/userInfoAtom";

export const BASE_PATH = process.env.REACT_APP_VFUN_API_DNS;
export const POST_GET_MEMBERINFO = `${BASE_PATH}/api/vfun/member_info`;

export interface IMember {
  data: {
    nickName: string | null;
    user_Serial: string;
    user_birthday: string;
    user_id: string;
    user_profile_key: string;
    profile_image_url: string;
  };
  result: number;
  sso_info: string;
}

export const GET_MEMBERINFO_G = (headers: headersType) => {
  return VFUN({
    url: POST_GET_MEMBERINFO,
    method: "get",
    headers,
    withCredentials: true
  });
};

export const logoutApi = `${BASE_PATH}/api/vfun/logout_proc`;

//// 언어 API (Languages)
interface ILangData {
  lang: string;
  lang_code: string;
}

export interface ILang {
  result: number;
  country: string;
  data: ILangData[];
  default_language: {
    lang: string;
    lang_code: string;
  };
}

export const getLanguage = (service_code: string, langCode: string) =>
  axios
    .get(
      `${BASE_PATH}/api/vfun/support_languages?service_code=${service_code}&lang=${langCode}`
    )
    .then((res) => res.data);

export interface ILauncherData {
  protocol: string;
  service_code: string;
  user_id: string;
  user_key: string;
  user_otp: string;
  game_run_url: string;
}

export const launcherStartApi = `${BASE_PATH}/api/auth/LauncherStart`;

export const refreshTokenApi = `${BASE_PATH}/api/vfun/refresh_token`;
