import "./Profile.scss";
import {
  isLauncherState,
  userIdState,
  userNicknameState,
  userProfileKeyState
} from "recoil/userInfoAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins,
  faCreditCard,
  faHeadset,
  faPowerOff,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { t } from "i18next";
import { dropBoxToggleState } from "../recoil/recoil";
import {
  BILLING_CHARGE_URL,
  Service_CN,
  Service_GL
} from "utils/config/Config";
import Grade from "./Grade";
import Wallet from "./Wallet";
import { useLogout } from "component/CombineMenu/hooks/useLogout";
import UserProfileImg from "component/UserProfileImg";
import { serviceCodeState } from "recoil/commonUiAtom";
import { redirectURL } from "utils/CommonFunc";

function Profile() {
  const userProfileKey = useRecoilValue(userProfileKeyState);
  const userNickname = useRecoilValue(userNicknameState);
  const userId = useRecoilValue(userIdState);
  const [dropBoxToggle, setDropBoxToggle] = useRecoilState(dropBoxToggleState);
  const displayStyle = {
    display: dropBoxToggle.profile ? "inline-block" : "none"
  };
  const service_code = useRecoilValue(serviceCodeState);
  const isLauncher = useRecoilValue(isLauncherState);

  const onClickLogout = useLogout();

  return (
    <div className="box drop" id="profile" style={displayStyle}>
      <div
        className="shadow"
        onClick={() =>
          setDropBoxToggle((prev) => ({ ...prev, profile: false }))
        }
        style={displayStyle}
      />
      <p className="title">{t("profile")}</p>
      <div className="row-flex">
        <a href={`${process.env.REACT_APP_LOUNGE_DNS}/space/${userProfileKey}`}>
          <UserProfileImg />
          <p className="nickname ellipsis">
            {userNickname === "" ? userId : userNickname}
          </p>
        </a>
        {Service_GL && !Service_CN ? <Grade /> : null}
      </div>
      <Wallet />

      <ul className="info-wrap y-scroll">
        <li>
          <p className="sub-title">{t("cate_pay")}</p>
          <ul className="gray-box">
            <li>
              <button
                onClick={() =>
                  redirectURL(
                    BILLING_CHARGE_URL(service_code, 2, false) as string,
                    "_self",
                    isLauncher
                  )
                }
              >
                <FontAwesomeIcon icon={faCreditCard} />
                {t("charging_history")}
              </button>
            </li>
            <li>
              <button
                onClick={() =>
                  redirectURL(
                    BILLING_CHARGE_URL(service_code, 3, false) as string,
                    "_self",
                    isLauncher
                  )
                }
              >
                <FontAwesomeIcon icon={faCoins} />
                {t("usage_history")}
              </button>
            </li>
          </ul>
        </li>
        <li>
          <p className="sub-title">{t("support")}</p>
          <ul className="gray-box">
            <li>
              <a
                href={`${process.env.REACT_APP_PLATFORM_DNS}/customer/inquiry?service_code=vfun`}
              >
                <FontAwesomeIcon icon={faHeadset} />
                {t("my_inquiries")}
              </a>
            </li>
          </ul>
        </li>
        <li>
          <p className="sub-title">{t("account")}</p>
          <ul className="gray-box">
            <li>
              <a
                href={`${process.env.REACT_APP_PLATFORM_DNS}/membership/sns_email_auth_confirm`}
              >
                <FontAwesomeIcon icon={faUser} />
                {t("account_info")}
              </a>
            </li>
            <li>
              <Link to={window.location.href} onClick={onClickLogout}>
                <FontAwesomeIcon icon={faPowerOff} />
                {t("signout")}
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default Profile;
