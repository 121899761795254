import { selector } from "recoil";
import { createBooleanAtom, createStringAtom } from "./commonAtom";

//// 유저 정보
export const isLoginState = createBooleanAtom("isLoginState", false);
export const loginLoadingState = createBooleanAtom("loginLoadingState", false);
export const isMobileAppState = createBooleanAtom("isMobileAppState", false);
export const isLauncherState = createBooleanAtom("isLauncherState", false);

interface ILoginStateProps {
  isLogIn: boolean;
  isLauncher: boolean;
  isMobile: boolean;
}

export const isLoginStateSelector = selector({
  key: "isLoginStateSelector",
  get: ({ get }) => {
    const isLauncher = get(isLauncherState);
    const isLogIn = get(isLoginState);
    const isMobile = get(isMobileAppState);

    const data: ILoginStateProps = {
      isLogIn: isLogIn,
      isLauncher: isLauncher,
      isMobile: isMobile
    };

    return data;
  }
});

export const ssoinfoState = createStringAtom("ssoinfoState", "none");

export const userIdState = createStringAtom("userIdState", "none");

export const userBirthState = createStringAtom("userBirthState", "none");

export const userEmailState = createStringAtom("userEmailState", "");

export const userReEmailState = createStringAtom("userReEmailState", "");

export const juminState = createStringAtom("juminState", "none");

export const userNicknameState = createStringAtom("userNicknameState", "");

export const userProfileKeyState = createStringAtom("userProfileKeyState", "");

// headersState는 api에 공통으로 들어가는 값이기 때문에 api header 넣는 용도로만 사용
export type headersType = {
  Ssoinfo: string;
  Userid: string;
  Userbirth: string;
  // device: string;
  // token: string;
  // "device-id": string;
};

export const headersState = selector<headersType>({
  key: "headersState",
  get: ({ get }) => {
    const ssoinfo = get(ssoinfoState);
    const userId = get(userIdState);
    const userBirth = get(userBirthState);

    const info = {
      Ssoinfo: ssoinfo,
      Userid: userId,
      Userbirth: userBirth
      // device: isLauncher ? "launcher" : "web",
      // token: token,
      // "device-id": device_id
    };
    return info;
  }
});

export const userProfileImgState = createStringAtom("userProfileImgState", "");
