import { useQuery } from "react-query";
import { getCustomList, oneDeps } from "component/CombineMenu/api/sideMenuList";
import { useRecoilValue } from "recoil";
import { langCodeState, serviceCodeState } from "recoil/commonUiAtom";
import { Link } from "react-router-dom";

const list = ["billing", "billing-qa"];

export default function CustomList() {
  const service_code = useRecoilValue(serviceCodeState);
  const langCode = useRecoilValue(langCodeState);
  const siteUrl = new URL(window.location.href).origin;
  const isBilling = list.some((domain) => siteUrl.includes(domain));
  const serviceCode = isBilling ? "billing" : service_code;

  const { isLoading, data } = useQuery(
    ["custom-list", serviceCode, langCode],
    () => getCustomList(serviceCode, langCode)
  );

  const linkUrl = (type: string, url: string) => {
    let redirectUrl;

    if (type === "lounge") {
      redirectUrl = `${process.env.REACT_APP_LOUNGE_DNS}${url}`;
    } else if (type === "site") {
      redirectUrl = `${siteUrl}${url}`;
    } else if (type === "vfun") {
      redirectUrl = `${process.env.REACT_APP_PLATFORM_DNS}${url}`;
    } else if (type === "ground") {
      redirectUrl = `${process.env.REACT_APP_GROUND_DNS}${url}`;
    } else if (type === "blank") {
      redirectUrl = url;
    } else {
      return;
    }

    window.open(redirectUrl, type === "blank" ? "_blank" : "_self");
  };

  if (isLoading) return <div>Loading</div>;

  if (data?.result !== 1) return null;
  if (data?.data.menu === undefined) return null;

  return !isLoading ? (
    <div>
      {data.data.title_type === "logo" ? (
        <a href={data.data.title_url} className="logo">
          <img src={data.data.game_logo} />
        </a>
      ) : (
        <div className="title">{data.data.game_title}</div>
      )}

      {data.data.menu.map((i, idx) => {
        if ("sub" in i && i.sub.length > 0) {
          return (
            <div key={idx}>
              <p className="sub-title">{i.title}</p>
              <ul>
                {i.sub.map((j, idx) => (
                  <li key={idx} onClick={() => linkUrl(j.type, j.url)}>
                    <Link to="#">{j.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        } else {
          const oneDepsItem = i as oneDeps;
          return (
            <Link
              to="#"
              onClick={() => linkUrl(oneDepsItem.type, oneDepsItem.url)}
              className="sub-title"
              key={idx}
            >
              {i.title}
            </Link>
          );
        }
      })}
    </div>
  ) : (
    <div>"Loading..."</div>
  );
}
