import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Service_GL } from "./config/Config";
import { initGA } from "./tracking";
import ReactGA from "react-ga4";
import { useRefreshToken } from "component/CombineMenu/hooks/useRefreshToken";
import { useSetRecoilState } from "recoil";
import { serviceCodeState, siteTypeState } from "recoil/commonUiAtom";
import { useLogout } from "component/CombineMenu/hooks/useLogout";

export default function ResetInfo() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const setServiceCode = useSetRecoilState(serviceCodeState);
  const setSiteType = useSetRecoilState(siteTypeState);
  const refreshToken = useRefreshToken();
  const logout = useLogout();

  useEffect(() => {
    const vfunSite =
      process.env.REACT_APP_PLATFORM_DNS === window.location.origin;
    const loungeSite =
      process.env.REACT_APP_LOUNGE_DNS === window.location.origin;
    const groundSite =
      process.env.REACT_APP_GROUND_DNS === window.location.origin;
    const service_code = !cookies["service_code"]
      ? "vfun"
      : cookies["service_code"];

    setServiceCode(vfunSite || loungeSite ? "vfun" : service_code);
    setSiteType(
      groundSite ? "ground" : loungeSite ? "lounge" : vfunSite ? "vfun" : "game"
    );
  }, [window.location.origin]);

  // uuid
  useEffect(() => {
    if (window.location.href.includes("uuid")) {
      const uuid = window.location.search.replace("?uuid=", "");
      setCookie("_vl", btoa(uuid));
    }
  }, []);

  // google GA
  useEffect(() => {
    if (
      window.location.hostname.includes(".valofe.com") ||
      window.location.hostname.includes("localhost")
    ) {
      if (!Service_GL) {
        initGA("G-5V54JQQ53S");
        ReactGA.send("pageview");
        ReactGA.event({
          category: "User",
          action: "Created an Account",
          label: "VFUN KR 회원 가입"
        });
      }
    }
  }, []);

  useEffect(() => {
    refreshToken();
  }, [window.location.href]);

  return null;
}
